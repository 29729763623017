.product-foundation-vto {
  &__container {
    .see-all-shades,
    .see-my-perfect {
      font-size: 12px;
    }
  }
  &__mobile-ctrl {
    .container {
      .product-sub-heading,
      .product-name {
        font-size: 12px;
      }
    }
  }
}

.device-mobile {
  .page-sticky-footer {
    &__right {
      .sticky-footer-right-menu-menu {
        .field-mobile-menu {
          ul {
            &.menu {
              a {
                &.button {
                  border: unset;
                  font-weight: unset;
                  height: 10px;
                  line-height: 0.7;
                }
              }
              li {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
