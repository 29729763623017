.welcome15-overlay.alt_layout1 {
  .email_popover {
    .email_popover__social_icons {
      top: 335px;
    }
  }
}

#optanon {
  #optanon-popup-body-right {
    #optanon-popup-more-info-bar {
      @media #{$medium-up} {
        width: 27%;
      }
    }
  }
}
