// Overrides for the re-nutriv boutiques.
// Note that this uses !important to work around the overspecific setting of
// fonts in the base versions.
// Alternatives would be overrides for each individual element.

// Re-nutriv
#rn_container {
  .renutriv_section_wrapper {
    .renutriv_section {
      .content {
        // Section headlines
        h3 {
          font-family: '#{$pf_bodoni_text}' !important;
        }
        // Section text blocks
        p {
          font-family: '#{$helvetica_neue_57cond}' !important;
        }
        // Section links
        a {
          font-family: '#{$helvetica_neue_47ltcn}' !important;
        }
      }
    }
    #renutriv_section-1 {
      img.full {
        max-height: 756px;
        max-width: 1299px;
        left: 0%;
        height: 100% !important;
      }
      .content {
        top: 180px;
        left: 174px;
        max-width: 285px;
        text-align: center;
        .experience_now {
          margin-top: 0%;
          max-width: 158px;
          max-height: 40px;
          left: 62px;
        }
        a.video {
          width: 100%;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    #renutriv_section-4 {
      #renutriv_section-4_li1 {
        .content {
          .experience_now {
            img {
              top: 210px;
            }
          }
        }
      }
    }
    .fixed_background {
      overflow: hidden;
      min-width: 600px;
      top: 0px;
    }
    #renutriv_section-2 {
      display: none !important;
    }
  }
  // Side bar
  div.side_nav li a {
    font-family: '#{$helvetica_neue_57cond}' !important;
  }
}

// Re-nutriv ultimate diamond
#re-nutriv-alchemy_container {
  .re-nutriv-alchemy_section_wrapper {
    .re-nutriv-alchemy_section {
      // Section headlines
      h2,
      h3 {
        font-family: '#{$pf_bodoni_text}' !important;
      }
      // Section text blocks
      p {
        font-family: '#{$helvetica_neue_57cond}' !important;
      }
      // Section links
      a {
        font-family: '#{$helvetica_neue_47ltcn}' !important;
      }
    }
    // Bottom text
    .re-nutriv-alchemy_text {
      h3 {
        font-family: '#{$pf_bodoni_text}' !important;
        font-style: italic;
      }
      p {
        font-family: '#{$helvetica_neue_57cond}' !important;
      }
    }
  }
  .mpp__product-grid {
    .product_brief {
      .button--secondary {
        font-family: $helvetica_neue_47ltcn !important;
      }
    }
  }
}

.brand-renutriv {
  .product_brief {
    &__buttons--non-shaded {
      .product_brief {
        &__button--shop-now {
          font-family: $helvetica_neue_47ltcn;
        }
      }
    }
  }
  .selectBox-options {
    li {
      a {
        font: 14px/43px $helvetica_neue_47ltcn;
      }
    }
  }
  a.selectBox,
  a.selectbox {
    font: 14px/43px $helvetica_neue_47ltcn;
  }
  .main-content {
    .product-full {
      &__title,
      &__subtitle {
        font-family: '#{$pf_bodoni_text}' !important;
      }
      &__attribute {
        h5,
        p {
          font-family: '#{$helvetica_neue_57cond}' !important;
        }
      }
      &__detail-link,
      &__price-text,
      &__price {
        font-family: '#{$helvetica_neue_57cond}' !important;
      }
    }
    .spp-product {
      &__anchor--scroll {
        font-family: '#{$helvetica_neue_57cond}' !important;
      }
      &__details-header {
        font-family: '#{$pf_bodoni_text}' !important;
      }
      &__details-description {
        p,
        b {
          font-family: '#{$helvetica_neue_57cond}' !important;
        }
      }
      &__details-attribute {
        h5,
        p,
        li {
          font-family: '#{$helvetica_neue_57cond}' !important;
        }
      }
      &__details-description {
        font-family: '#{$helvetica_neue_57cond}' !important;
      }
      &__mini-bag-description {
        h5,
        h6 {
          font-family: '#{$pf_bodoni_text}' !important;
        }
      }
    }
    .cta.button--dark,
    .selectBox-label,
    .free_standard_shipping_returns,
    .product-full__wishlist {
      font-family: '#{$helvetica_neue_57cond}' !important;
    }
  }
  .back-to-top {
    width: 85px;
  }
}

.page-product-re-nutriv {
  font-family: $roboto-condensed !important;
  .main-content {
    .product-full {
      font-family: $roboto-condensed;
      &__title {
        font-family: $bb-roman;
      }
      &__attribute *,
      &__detail-link,
      .cta,
      &__share,
      &__wishlist {
        font-family: $roboto-condensed;
      }
    }
    .discover-more {
      &__header,
      &__products {
        font-family: $roboto-condensed;
      }
    }
    .discover_more__product-header,
    .discover_more__product-sub-header {
      font-family: $bb-roman;
    }
    .discover_more__product-quickshop,
    .discover_more__product-shopnow {
      font-family: $roboto-condensed;
    }
    .spp-product {
      &__details-header,
      &__additional-section-button,
      &__mini-bag-header,
      &__mini-bag-sub-header {
        font-family: $bb-roman;
      }
      &__add-button,
      &__section-header,
      &__anchor,
      &__details-description,
      &__details-attribute *,
      &__details-attribute__label,
      &__wishlist,
      &__share,
      &__mini-bag-section,
      &__mini-bag-quantity,
      &__mini-bag-add-button {
        font-family: $roboto-condensed;
      }
      &__additional-section .headline--secondary span {
        font-family: $bb-roman !important;
      }
    }
    .select-markdown,
    .selectBox,
    .selectBox-options,
    .selectBox-dropdown-menu a,
    select {
      font-family: $roboto-condensed !important;
    }
  }
  #pr-review-display {
    .pr-rd-main-header {
      .pr-rd-review-header-contents {
        .pr-multiselect-button-label {
          font-family: $roboto-condensed;
        }
      }
      .pr-rd-review-header-sorts {
        .pr-accessible-text,
        .pr-rd-sort,
        option {
          font-family: $roboto-condensed;
        }
      }
      .pr-rd-review-total {
        font-family: $roboto-condensed;
      }
    }
    .pr-review {
      .pr-rd-review-headline,
      .pr-rd-bold,
      time,
      span,
      .pr-rd-description-text,
      dt,
      dd,
      .pr-underline {
        font-family: $roboto-condensed;
      }
    }
    .pr-rd-main-footer {
      .pr-rd-content-block {
        .pr-rd-review-position {
          span {
            font-family: $roboto-condensed;
          }
        }
        .pr-rd-pagination {
          .pr-rd-pagination-btn {
            font-family: $roboto-condensed;
          }
        }
        .pr-rd-to-top {
          font-family: $roboto-condensed;
        }
      }
    }
  }
  .spp-reviews {
    .pr-header {
      font-family: $bb-roman;
    }
  }
  .pr-snippet-read-and-write,
  .pr-snippet-write-review-link {
    font-family: $roboto-condensed !important;
  }
}

#re-nutriv-alchemy_section-6 {
  .re-nutriv-alchemy_text {
    h2 {
      @include swap_direction(margin, 0 0 0.1em !important);
    }
  }
}

@media screen and (max-width: 320px) {
  #re-nutriv-alchemy_section-6 {
    .re-nutriv-alchemy_text {
      p {
        line-height: 17px;
      }
    }
  }
  .brand-renutriv {
    .product-brief {
      &__button-quickshop {
        font-size: 11px;
      }
    }
    .video_youtube {
      &.fonts-theme-re-nutriv {
        header {
          .video-headline-mobile {
            top: -100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 570px) and (orientation: landscape) {
  #re-nutriv-alchemy_section-6 {
    .re-nutriv-alchemy_text {
      p {
        line-height: 30px;
      }
    }
  }
}

@media screen and (min-width: 330px) and (max-width: 380px) {
  #re-nutriv-alchemy_section-6 {
    .re-nutriv-alchemy_text {
      p {
        line-height: 20px;
      }
    }
  }
  .brand-renutriv {
    .product-brief {
      &__button-quickshop {
        font-size: 12px;
      }
    }
  }
}
