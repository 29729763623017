// Abstract specific font names away
$helvetica_neue_47ltcn: 'HelveticaNeueLTW06-47LtCn';
// Helvetica Neue
@font-face {
  font-family: '#{$helvetica_neue_47ltcn}';
  src: url('#{$netstorage-font-path}helvetica/HelveticaNeueLTW06-47LtCn.woff2') format('woff2');
  font-display: swap;
}

$helvetica_neue_55roman: 'HelveticaNeueLTW06-55Roman';
@font-face {
  font-family: '#{$helvetica_neue_55roman}';
  src: url('#{$netstorage-font-path}helvetica/HelveticaNeueLTW06-55Roman.woff2') format('woff2');
  font-display: swap;
}

$helvetica_neue_57cond: 'HelveticaNeueLTW06-57Cond';
@font-face {
  font-family: '#{$helvetica_neue_57cond}';
  src: url('#{$netstorage-font-path}helvetica/HelveticaNeueLTW06-57Cond.woff2') format('woff2');
  font-display: swap;
}

// PF Bodoni Text
$pf_bodoni_text: 'PFBodoniText';
@font-face {
  font-family: '#{$pf_bodoni_text}';
  src: url('#{$netstorage-font-path}bauer-bodoni/PFBodoniText-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

// PF Futura Neu
$pf_futura_neu_light: 'PFFuturaNeu-Light';
@font-face {
  font-family: '#{$pf_futura_neu_light}';
  src: url('#{$netstorage-font-path}Futura/PFFuturaNeu-Light.woff2') format('woff2');
  font-display: swap;
}

$pf_futura_neu_medium: 'PFFuturaNeu-Medium';
@font-face {
  font-family: '#{$pf_futura_neu_medium}';
  src: url('#{$netstorage-font-path}Futura/PFFuturaNeu-Medium.woff2') format('woff2');
  font-display: swap;
}

$pf_futura_neu_bold: 'PFFuturaNeu-Bold';
@font-face {
  font-family: '#{$pf_futura_neu_bold}';
  src: url('#{$netstorage-font-path}Futura/PFFuturaNeu-Bold.woff2') format('woff2');
  font-display: swap;
}

$chianti_roman: 'chianti-bt-wgl4';
$source-sans-3: 'Source Sans 3';
// Resolving Chianti-bt-wgl4 to Source Sans 3 during transition
@font-face {
  font-family: '#{$chianti_roman}';
  src: url('#{$netstorage-font-path}SourceSans3/source-sans-3-regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: '#{$source-sans-3}';
  src: url('#{$netstorage-font-path}SourceSans3/source-sans-3-regular.woff2') format('woff2');
  font-display: swap;
}
$optimaregular: $source-sans-3, 'Optima Display Roman';
$optimalight: $source-sans-3, 'OptimaDisplayLight';
$optimalightroman: 'Arsenal';
$akzidenzgrotesk: 'AkzidenzGrotesk W1G';
