.page-products-aerin,
.page-product-aerin,
.brand-aerin {
  .page-main {
    font-family: $pf_futura_neu_light;
    .product_brief {
      &__header,
      &__sub-header,
      &__price,
      &__full-details,
      a.selectBox,
      &__price-size {
        font-family: $pf_futura_neu_light;
      }
    }
    .product-full {
      &__title,
      &__detail-link,
      h3,
      a.selectBox,
      &__wishlist {
        font-family: $pf_futura_neu_light;
      }
      &____attribute {
        p {
          font-family: '#{$pf_futura_neu_medium}';
        }
      }
      &__price-text {
        font-family: '#{$pf_futura_neu_medium}';
        span {
          font-family: '#{$pf_futura_neu_medium}';
        }
      }
      &__add-button {
        font-family: '#{$pf_futura_neu_medium}';
      }
    }
    .spp-product {
      &__details-header,
      &__details-description,
      &__anchor--scroll {
        font-family: $pf_futura_neu_light;
      }
    }
    .discover_more {
      &__product-price,
      &__header {
        font-family: $pf_futura_neu_light;
      }
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .headline--primary,
    .link--bold,
    .estee-edit__header-tags-label,
    .text--short,
    label,
    .cart-item__qty,
    .headline--tertiary {
      font-family: $pf_futura_neu_light;
    }
    h2.mpp__header {
      color: $extended_mpp_black_pearl;
    }
  }
}

.brand-aerin {
  .page-main {
    .product-full__attribute {
      p {
        font: 15px/24px $pf_futura_neu_light;
      }
    }
    .section-store-locator {
      &.device-pc {
        .store-locator {
          .store-locator__title {
            font-family: $pf_futura_neu_light;
          }
        }
      }
    }
    .headline--products,
    .mpp__header,
    .product_brief__misc-flag,
    .select-markdown,
    select,
    a.selectBox,
    a.selectbox,
    .text-promo {
      font-family: $pf_futura_neu_light;
    }
    .subcopy.text-promo__subcopy {
      div {
        font-family: $pf_futura_neu_light !important;
      }
    }
    .spp-product__mini-bag-add-button {
      font-family: '#{$pf_futura_neu_medium}';
    }
    .text-product-listing-names-main,
    .product-brief__header,
    .spp-product__details-header,
    .spp-product__section-header,
    .text-product-listing-names-sub,
    .product-brief__sub-header {
      font-family: $pf_futura_neu_light;
    }
    .ff-results__regimen-step-product {
      .product_brief__header {
        font-family: $pf_futura_neu_light;
      }
    }
    .ff-results__regimen-step-product & {
      .product_brief__header {
        font-family: $pf_futura_neu_light;
      }
    }
    .beauty-feed-tout--products {
      .sku-brief-editorial__shadename {
        font-family: $pf_futura_neu_light;
      }
    }
    .ff-results {
      &__product {
        .sku-brief-editorial__shadename {
          font-family: $pf_futura_neu_light;
        }
      }
      &__regimen-step-product {
        .product_brief__sub-header {
          font-family: $pf_futura_neu_light;
        }
      }
    }
    .ff-results__product & {
      .sku-brief-editorial__shadename {
        font-family: $pf_futura_neu_light;
      }
    }
    .ff-results__regimen-step-product & {
      .product_brief__sub-header {
        font-family: $pf_futura_neu_light;
      }
    }
    .beauty-feed-tout--products & {
      .sku-brief-editorial__shadename {
        font-family: $pf_futura_neu_light;
      }
    }
  }
  .woa-blog__content {
    .ee-disco-more__label {
      font-family: $pf_futura_neu_light;
    }
  }
  .back-to-top {
    font-family: $pf_futura_neu_light;
  }
  .spp-product__details {
    .spp-product__details-header,
    .spp-product__details-description {
      font-family: $pf_futura_neu_light !important;
    }
  }
}

.section-store-locator {
  &.device-pc {
    .store-locator {
      .brand-aerin {
        .page-main {
          .store-locator__title {
            font-family: $pf_futura_neu_light;
          }
        }
      }
    }
  }
}

.aerin-slot-button,
.aerin-video-block__pretitle,
.aerin_headline_block--subtitle {
  font-family: $pf_futura_neu_light;
}

.hero-block--aerin {
  .hero-block__promo {
    .text-promo__content {
      font: 15px/24px $pf_futura_neu_light;
    }
  }
}
