@media only screen and (max-width: 450px) {
  .header-items .page-utilities {
    &__loyalty {
      .user-login-state {
        a.user-greeting {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: #{lower-bound($iphone6-range)}) {
  #loyalty__content {
    .e-list_not_signed {
      &-right {
        a.btn_join {
          padding: 0 25px;
        }
      }
    }
  }
}

#loyalty__content {
  // Reinstate the program title but with our styling tweaks
  .e-list_not_signed {
    .title_super {
      display: block;
      visibility: visible;
    }
    .title_main {
      display: block;
      visibility: visible;
      margin-top: 0px;
      margin-left: 0px;
    }
    .e-list_not_signed-right {
      .btn_join {
        background: #040a2b;
        color: white;
      }
    }
    @media #{$small-only} {
      .title_super {
        font-family: '#{$source-sans-3}';
      }
      .title_main {
        font-family: '#{$source-sans-3}';
        font-size: 32pt;
      }
      .sub_title {
        font-size: 30px;
      }
    }
  }
}

#samples {
  &-panel {
    .samples-step {
      h2 {
        font-size: 2em;
      }
    }
  }
}

.section-loyalty-programs {
  &.node-type-page.device-mobile {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      .page-wrapper {
        padding-top: 88px;
      }
    }
  }
  .loyalty-tier {
    &:nth-of-type(1) {
      background-color: #0f1b2a;
      div {
        .hero-tout-mobile {
          &__content--a {
            margin-top: 0px;
            min-height: 200px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
              min-height: 250px;
            }
            &:first-child {
              margin-top: 0px;
            }
          }
          &__hero--a {
            z-index: auto;
            top: 0px;
            img {
              @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                width: auto;
                height: 100%;
              }
            }
          }
        }
      }
    }
    .tier_header {
      &.points-title {
        margin-top: 0.5em;
        .div_content_body + img {
          display: none;
        }
      }
    }
    #loyalty-main-header {
      background-color: #0f1b2a;
      color: #a5976e;
      text-align: center;
      margin-bottom: 1.5em;
      padding: 3em 0 0em;
      h3 {
        font-size: 3em;
        letter-spacing: 0.02em;
        line-height: 0.8em;
        font-family: $akzidenz;
      }
      p.sub_head {
        font-family: $optimaregular;
        font-style: italic;
        font-size: 2em;
        text-align: right;
        font-weight: bold;
        letter-spacing: 0.15em;
        line-height: 1.2em;
        margin: 0 1em 0 0;
      }
    }
    .tier_header {
      .amount,
      .unit {
        font-weight: bold;
        font-family: $optimaregular;
        color: #a5976e;
      }
      .amount {
        font-size: 2.5em;
      }
      &.points-title {
        /* Tier header side lines */
        display: block;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        p {
          position: relative;
          display: inline-block;
          margin: 0.4em 0;
          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 9999px;
            height: 2px;
            background: #ae9e71;
          }
          &:before {
            right: 100%;
            margin-right: 15px;
          }
          &:after {
            left: 100%;
            margin-left: 15px;
          }
        }
      }
    }
    .pc-carousel-formatter {
      margin-bottom: 0px;
      .slick-carousel {
        margin-bottom: 2.5em;
        .slick-dots {
          bottom: auto;
          li button {
            &:before {
              color: #ae9e71;
            }
          }
        }
      }
    }
    .aerin-landing-row {
      margin-bottom: 0px;
      &__slot {
        min-height: unset;
        &:nth-child(2) {
          margin-top: 14%;
        }
        .basic-textarea-v1 {
          text-align: center;
          p {
            color: #a5976e;
            font-size: 1.8em;
            margin: 0.5em 0;
          }
        }
      }
      .basic-3-col-table {
        &-column-title {
          color: #ae9e71;
          font-weight: bold;
          font-size: 1.5em;
          font-family: $optimaregular;
        }
        &-column-copy {
          p {
            margin: 0px;
          }
        }
        &-column-title,
        &-column-copy {
          text-align: center;
        }
      }
    }
    .discover-more {
      padding-bottom: 0px;
      &__products {
        width: 100%;
        .discover_more__product {
          padding-bottom: 0.5em;
          &-image-container {
            width: 88%;
            margin: 0 6%;
            height: auto;
          }
          &-image {
            width: 80%;
            height: auto;
            left: 10%;
            position: relative;
          }
          &-header {
            margin-top: 0.4em;
            font-size: 1.4em;
          }
          &-sub-header {
            font-size: 1.2em;
          }
          &-header,
          &-sub-header {
            text-align: center;
          }
          &-price {
            display: none;
          }
          &-quickshop {
            display: none;
          }
        }
      }
    }
    .or-title {
      margin-top: 1em;
    }
    .accordian-page__accordian {
      & > .content-block-title {
        color: #a5976e;
        font-weight: bold;
        text-align: center;
        padding: 0 0.5em;
        margin-bottom: 0.3em;
      }
    }
    .bold {
      font-weight: bold;
    }
    .tier1-content {
      .basic-textarea-v1 {
        p {
          margin-top: 0px;
        }
      }
    }
    #tier4-title {
      margin-top: 2em;
    }
    .tier4-content {
      .aerin-landing-row {
        &__slot {
          height: auto !important;
          .aerin-landing-row__slot {
            &:nth-child(2) {
              margin-top: 20%;
              margin-bottom: 10%;
              p {
                margin: 0px;
              }
            }
          }
          &:nth-child(2) {
            margin-top: 3em;
          }
        }
      }
    }
    #bottom_info {
      text-align: center;
      margin: 0 1em 1em;
      margin-top: 5%;
      .main_line {
        color: #a5976e;
        font-size: 1.2em;
        margin-bottom: 0.8em;
      }
      p {
        line-height: 1.2em;
        margin: 0;
      }
    }
  }
}

.discover_more {
  &__product-image {
    margin: 0 auto;
  }
  &__product-price {
    display: none;
  }
  &__product-header {
    line-height: 1.8;
    font-family: $source-sans-3;
  }
}

.pc-carousel-formatter {
  .slick-carousel {
    .slick-dots {
      bottom: -5%;
      button {
        &::before {
          color: $color-navy;
        }
      }
    }
  }
}

.tier_header {
  .div_content_body {
    p {
      font-size: 40px;
      line-height: normal;
      font-family: $source-sans-3;
      font-weight: 400;
      margin: 0 auto;
    }
  }
}

.loyalty_header {
  max-width: $medium-max;
  margin: 0 auto 100px;
  position: relative;
  p {
    margin: 20px;
  }
}

.cta-btn {
  display: block;
  background: $color-navy;
  font-size: 12px;
  font-family: $akzidenz;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  color: $color-white;
  max-width: 200px;
  margin: 1.5rem auto 0;
  padding: 1rem;
}

.loyalty_mrkt_ldng {
  .loyalty_points {
    width: 90%;
    margin: 50px auto;
    padding: 10px;
  }
  hr {
    width: 75%;
    border: 0;
    height: 1.5px;
    background: $color-navy;
    margin-bottom: 50px;
  }
}
