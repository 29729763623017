.mobile-search-page {
  .search-page__help {
    .search-live_chat {
      margin-bottom: 10px;
    }
  }
}

.responsive-footer {
  .footer-main-menu {
    .need-help-chat-now {
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .device-mobile {
    .responsive-footer {
      .footer-main-menu {
        .need-help-chat-now {
          padding-left: 0;
        }
      }
    }
  }
}

#cs-container {
  &.mobile-unsubscribe,
  &.email-signup-thanks {
    padding: 10px;
    min-height: 250px;
    input[type='text'] {
      width: 100%;
    }
  }
}

@media #{$small-only} {
  .footer-forms-location-sms-email-signup-elc-nodeblock {
    .email-signup {
      padding-bottom: 0px;
    }
  }
  .device-mobile {
    &.section-estee-stories-article-kendalls-loves {
      .colorbox__youtube {
        #cboxContent {
          #cboxLoadedContent {
            top: 0;
          }
        }
      }
    }
  }
}

.ff-quiz {
  &__button {
    &--next {
      width: 120px;
    }
  }
  &__slide--4 {
    .ff-quiz {
      &__question {
        .ff-quiz {
          &__answer--medium {
            display: none;
            &-deep {
              display: block;
            }
          }
        }
      }
    }
  }
}

.brand-renutriv {
  .video_youtube {
    &.fonts-theme-re-nutriv {
      header {
        .video-headline-mobile {
          top: -50px;
        }
      }
    }
  }
  .page-sticky-footer {
    .button {
      background-color: transparent;
      border: none;
    }
  }
}

@media only screen and (max-width: 370px) and (orientation: portrait) {
  .device-mobile {
    .page-sticky-footer {
      .page-sticky-footer {
        &__right,
        &__inner {
          .field-mobile-menu {
            ul.menu {
              text-align: $ldirection !important;
              @include swap_direction(padding, 0px 0px 0px 5px !important);
              li {
                @include swap_direction(padding, 0 2px 0 4px !important);
                margin-top: 0px;
              }
              a {
                font-size: 11px;
                &.button {
                  padding: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 345px) and (orientation: portrait) {
  .device-mobile {
    .page-sticky-footer {
      .page-sticky-footer {
        &__right,
        &__inner {
          .field-mobile-menu {
            ul.menu {
              a {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 400px) and (orientation: portrait) {
  .device-mobile {
    .page-sticky-footer {
      .page-sticky-footer {
        &__right,
        &__inner {
          .field-mobile-menu {
            ul.menu {
              a {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 375px) and (orientation: portrait) {
  .device-mobile {
    .page-sticky-footer {
      .page-sticky-footer {
        &__right,
        &__inner {
          .field-mobile-menu {
            ul.menu {
              li {
                @include swap_direction(padding, 0 0px 0 7px !important);
                margin-top: 0px;
              }
              a {
                &.button {
                  padding: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .section-pure-color-love {
    .pc-love-swatches {
      &__header--title {
        font-size: 65px;
      }
    }
    #pc_love__yourLook-text1 {
      p {
        font-size: 35px;
        word-wrap: break-word;
        line-height: 1em;
      }
    }
  }
}

@media only screen and (max-width: 321px) and (orientation: portrait) {
  .section-pure-color-love {
    .pc-love-swatches {
      &__header--title {
        font-size: 62px;
      }
    }
    #pc_love__yourLook-text1 {
      p {
        font-size: 35px;
        word-wrap: break-word;
        line-height: 1em;
      }
    }
  }
}

@media only screen and (min-width: 737px) {
  .device-mobile {
    .page-sticky-footer {
      .page-sticky-footer {
        &__right {
          position: relative;
          width: 100%;
        }
      }
    }
  }
}

.ff-results {
  &__matches-sub-headline,
  &__regimen-steps {
    display: none;
  }
}

.spp-product {
  .spp-product__description {
    .product-full__engraving-button {
      display: none;
    }
  }
  .page-product-re-nutriv & {
    .spp-product {
      &__header {
        font-family: $bb-roman;
      }
      &__attribute,
      &__attribute *,
      &__detail-link,
      &__additional-section-button,
      &__price-size {
        font-family: $roboto-condensed;
      }
    }
  }
}

.page-product-re-nutriv {
  .spp-product__reviews-header--mobile {
    font-family: $roboto-condensed;
  }
}

.field-mobile-menu {
  ul.menu {
    line-height: 18px;
    white-space: normal;
  }
}

.oculus_formatter {
  .oculus_mod1_wrapper {
    h2.oculus_hero_block {
      font-family: $source-sans-3;
    }
  }
}

h1 {
  font-family: $source-sans-3;
}

#pr-reviewdisplay {
  .pr-media-modal {
    .button__close {
      top: 25px;
      right: 10px;
    }
  }
  #pr-flag-reviews {
    .pr-flag-review-btn {
      margin-top: 10px;
    }
  }
}

.p-w-r {
  .pr-rd-helpful-action {
    .pr-media-modal {
      .pr-cross-icon {
        &__circle,
        &__line {
          stroke: $color-navy;
        }
      }
    }
  }
  .pr-review-display {
    .pr-flag-review {
      &-label {
        width: 275px;
      }
    }
  }
}
