.section-kendall-jenner-makeup-looks {
  #facechart-container {
    .facechart-intro {
      .column {
        h2 {
          font-family: $source-sans-3;
        }
      }
      blockquote {
        font-family: $source-sans-3;
        margin-top: 4.5%;
      }
    }
    .facechart_carousel-text {
      .steps {
        li {
          h2 {
            font-family: $source-sans-3;
          }
        }
      }
    }
    .facechart-products {
      h3 {
        font-family: $source-sans-3;
      }
    }
  }
  @media screen and (max-width: 767px) {
    #facechart-container {
      .facechart-steps-container {
        blockquote {
          &.mobile {
            font-family: $source-sans-3;
          }
        }
      }
    }
  }
}
