.product-vto__youcam-module-container {
  @media #{$medium-up} {
    z-index: 99;
  }
}

.product-vto {
  .cta-vto {
    @media #{$medium-up} {
      z-index: 98;
    }
  }
}
//Product Foundation VTO
.product-foundation-vto {
  &__btn {
    bottom: 100px;
  }
  &__mobile-ctrl {
    .container {
      .product-full__add-button {
        font-size: 10px;
        letter-spacing: normal;
        padding: 0 5px;
      }
    }
  }
  &__container {
    ul.shade-list-vto {
      li {
        &.vto-filter {
          &.first-filter {
            margin-#{$ldirection}: 10px;
          }
        }
        .vto-filter-text {
          font-size: 9px;
          position: absolute;
          #{$ldirection}: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.page-main {
  .sd-product-grid {
    .elc-product-grid-header {
      font-family: $source-sans-3;
    }
  }
}

.spp-product__header,
.product-full__title {
  font-family: $optimalight;
  @media #{$medium-up} {
    font-family: $source-sans-3;
  }
}
