ul.vto-skin-care-screens {
  &__tips-container {
    li {
      padding: 0 3px 2px;
      font-family: $akzidenzgrotesk;
      font-size: 20px;
      font-weight: 400;
    }
  }
}
.vto-skin-care-formatter {
  .vto-skin-care-screens {
    &__landing-title,
    &__tips-title {
      font-family: $source-sans-3;
      font-weight: 400;
    }
    &__tips-block-container {
      min-height: 210px;
    }
  }
}
