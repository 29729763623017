.section-3-minute-beauty {
  #tmb_container {
    .mb_boutique_quote {
      h3 {
        font-family: $source-sans-3;
      }
    }
    .mb_boutique_content {
      h2 {
        font-family: $source-sans-3;
      }
    }
  }
  #tips_boutique {
    h1,
    h2,
    h3 {
      font-family: $source-sans-3;
    }
    .mpp-carousel {
      li {
        .product-header {
          h3,
          h4 {
            font-family: $source-sans-3;
          }
        }
      }
    }
  }
}
